// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use 'sass:map';
@use 'sass:color';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sonus-space-primary: mat.m2-define-palette(mat.$m2-green-palette);
$sonus-space-accent: mat.m2-define-palette(mat.$m2-deep-orange-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sonus-space-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
//$sonus-space-theme: mat.define-light-theme((
$sonus-space-theme: mat.m2-define-dark-theme((
  color: (
    primary: $sonus-space-primary,
    accent: $sonus-space-accent,
    warn: $sonus-space-warn,
  ),
  typography: mat.m2-define-typography-config(
    $body-2: mat.m2-define-typography-level($font-size: 12px),
  ),
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sonus-space-theme);


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

$config: mat.m2-get-color-config($sonus-space-theme);
$foreground: map.get($config, foreground);
$background: map.get($config, background);
$background-color: mat.m2-get-color-from-palette($background, background);
@debug "background #{$background-color}";
$default-color: mat.m2-get-color-from-palette($foreground, text);
$hint-color: mat.m2-get-color-from-palette($foreground, hint-text);
$primary-color: mat.m2-get-color-from-palette($sonus-space-primary, 500);
$primary-tame-color: mat.m2-get-color-from-palette($sonus-space-primary, 900);

.mat-button-toggle-checked {
  background-color: $primary-tame-color !important;
}

.highlight {
  color: $primary-color
}

@import "bs-datepicker.css";
// bs-datepicker.css custom dark theme
.theme-sonus {
  &.bs-datepicker {
    background: $background-color;
    color: $default-color;
  }

  .bs-datepicker-container {
    padding: 4px;
  }

  .bs-datepicker-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    height: auto;

    button {
      font-size: 14px;
      color: $default-color;
    }
  }

  .bs-datepicker-body {
    border: none;

    table {
      thead {
        th {
          color: $hint-color;
          padding-bottom: 8px;
        }
      }

      td {
        color: $default-color;

        span.is-other-month:not(.disabled):not(.selected) {
          color: color.adjust($hint-color, $alpha: -0.2);
        }
      }
    }

  }

  .bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span,
  .bs-datepicker-body table td span[class*=select-]:after, .bs-datepicker-body table td[class*=select-] span:after {
    background-color: $background-color;
    color: $primary-color;
  }

  .bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected),
  .bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
  .bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
    background-color: mat.m2-get-color-from-palette($sonus-space-primary, 0.3);
  }

  .bs-datepicker-body table td span.today {
    border: 1px solid $hint-color;

    &.selected {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
    }
  }

  .btn-today-wrapper .btn-success, .btn-clear-wrapper .btn-success {
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  .btn-today-wrapper .btn-success:focus, .btn-clear-wrapper .btn-success:focus {
    box-shadow: none;
  }

  .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
    background-color: #1e7e34;
    border-color: #1e7e34;
  }

  .btn-today-wrapper .btn-success:hover, .btn-clear-wrapper .btn-success:hover {
    background-color: #218838;
    border-color: #218838;
  }

  .bs-datepicker-predefined-btns button.selected {
    background-color: #5cb85c;
  }

  .bs-datepicker-body table td.week span {
    color: #5cb85c;
  }

  .bs-datepicker-body table td.active-week span:hover {
    cursor: pointer;
    background-color: #5cb85c;
    color: #fff;
    opacity: 0.5;
    transition: 0s;
  }
}
